<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <b-img
          :src="appLogoImage"
          alt="logo"
        />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Welcome to x7trade! 👋
          </b-card-title>
          <b-card-text
            v-if="isLoaded === false"
            class="mb-2"
          >
            We are processing your invitation, please hold.
          </b-card-text>
          <b-col
            v-if="isLoaded === false"
            class="text-center"
          >
            <b-spinner label="Loading..." />
          </b-col>
          <b-col
            v-if="accepted === true"
            class="mb-2"
          >
            Invitation successfully accepted, you may continue to
            <span class="font-weight-bolder">
              <router-link :to="{ name: 'dashboard' }">
                dashboard
              </router-link>
            </span>
          </b-col>
          <b-col
            v-if="failed === true"
            class="mb-2"
          >
            Unfortunately we were unable to accept the invitation.
          </b-col>
          <b-col>
            <b-card-text
              v-if="registering === true"
              class="mb-2"
            >
              Please register in order to access this company.
            </b-card-text>
            <register-form
              v-if="registering === true"
              :invitation-hash="invitation_hash"
              :invitation="invitation"
            />
          </b-col>
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BCardText,
  BCardTitle,
  BCol,
  BSpinner, BImg,
  BLink,
  BRow,
} from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import handleError from '@/views/components/errorHandler'
import RegisterForm from '@/views/users/RegisterForm.vue'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BCardText,
    BCardTitle,
    BImg,
    BSpinner,
    RegisterForm,
  },
  setup() {
    // App Name
    const { appLogoImage } = $themeConfig.app

    return {
      appLogoImage,
    }
  },
  data() {
    return {
      accepted: false,
      failed: false,
      isLoaded: false,
      registering: false,
      invitation_hash: null,
      invitation: {},
      sideImg: require('@/assets/images/pages/login-v2.svg'),
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  created() {
    this.invitation_hash = this.$route.query.invitation_hash
    this.$http.get(`/v1/invitation/accept/${this.invitation_hash}`)
      .then(() => {
        this.isLoaded = true
        this.accepted = true
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Invitation successfully accepted',
            icon: 'EditIcon',
            variant: 'success',
          },
        })
        this.$profile().fetchProfile()
      })
      .catch(error => {
        this.isLoaded = true
        if (error.response.data.mustRegister) {
          this.registering = true
          this.invitation = error.response.data.invitation
        } else {
          this.failed = true
          handleError(error, this.$toast)
        }
      })
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
