<template>
  <div>
    <!-- User Info: Input Fields -->
    <validation-observer ref="registerValidation">
      <b-form>
        <!-- Field: Full Name -->
        <b-row>
          <b-col
            cols="12"
          >
            <b-form-group
              label="Name"
              label-for="full-name"
            >
              <validation-provider
                #default="{ errors }"
                name="full-name"
                rules="required"
              >
                <b-form-input
                  id="full-name"
                  v-model="userData.name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <!-- Field: Email -->
        <b-row>
          <b-col
            cols="12"
          >
            <b-form-group
              label="Email"
              label-for="email"
            >
              <validation-provider
                #default="{ errors }"
                name="email"
                rules="required"
              >
                <b-form-input
                  id="email"
                  v-model="userData.email"
                  type="email"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            cols="12"
          >
            <b-form-group
              label="Phone"
              label-for="phone"
            >
              <validation-provider
                #default="{ errors }"
                name="phone"
              >
                <b-form-input
                  id="phone"
                  v-model="userData.phone"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <!-- Field: Password -->
          <b-col
            cols="12"
          >
            <b-form-group
              label="Password"
              label-for="password"
            >
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="login-password"
                    v-model="userData.password"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    :type="passwordFieldType"
                    name="login-password"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <!-- Action Buttons -->
    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      @click="validationForm"
    >
      Register
    </b-button>
  </div>
</template>

<script>
import {
  BButton, BRow, BCol, BFormGroup, BFormInput, BForm, BInputGroupAppend, BInputGroup,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate/dist/vee-validate.full'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import handleError from '@/views/components/errorHandler'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BForm,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  props: {
    invitation: { type: Object, default() {} },
    invitationHash: { type: String, default: '' },
  },
  data() {
    return {
      userData: {
        email: null,
        name: null,
        password: null,
        phone: null,
      },
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  created() {
    this.userData.email = this.$props.invitation.email
    this.userData.name = this.$props.invitation.name
  },
  methods: {
    validationForm() {
      this.$refs.registerValidation.validate().then(success => {
        if (!success) {
          return
        }
        this.register()
      })
    },
    register() {
      this.$http.post('/v1/invitation/register', {
        email: this.$data.userData.email,
        name: this.$data.userData.name,
        password: this.$data.userData.password,
        phone: this.$data.userData.phone,
        invitation_hash: this.$props.invitationHash,
      })
        .then(() => {
          this.$router.push({ name: 'login' })
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Successfully registered.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          handleError(error, this.$toast)
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
